<template>
  <v-card elevation="0" outlined>
    <router-link :to="{ name: 'OverView', params: { id: carDetail.ListingId } }">
      <v-img v-if="imageLink" :src="$store.getters.hostURL + imageLink[0]" />
      <div
        style="height: 194px"
        class="d-flex align-items-top justify-content-center rounded-top border-bottom"
        v-else
      >
        <v-img
          :src="require('@/assets/img/nophoto.jpg')"
          alt="no photo placeholder"
          blank-color="black"
          class="carImg"
          rounded="top"
          fluid-grow
        />
      </div>
      <div class="rounded-circle bg-white border heartCircle">
        <HeartIcon v-if="fav" @click="setWishList()" fill-color="#DB4437" />
        <HeartOutlineIcon v-if="!fav" @click="setWishList()" />
      </div>
    </router-link>
    <v-card-title class="h5 comingSoon text-muted" v-if="!imageLink">
      {{ $t("global.label.comingsoon") }}
    </v-card-title>
    <v-card-title>
      <router-link
        :to="{ name: 'OverView', params: { id: carDetail.ListingId } }"
        class="cardTitle"
      >
        {{ carDetail.Year }} {{ carDetail.Make }} {{ carDetail.Model }}
      </router-link>
    </v-card-title>
    <v-card-subtitle>
      <span v-if="carDetail.Trim"> {{ carDetail.Trim }} </span>
    </v-card-subtitle>
    <v-card-text class="detailText pb-0">
      <span v-if="carDetail.Kilometers">{{ carDetail.Kilometers }}  {{ $t("Overview.KM") }}</span>
      <span v-else> [Mileage] </span>
      <span v-if="carDetail.Transmission">
        <span class="divider">|</span> {{ carDetail.Transmission }}</span
      >
      <span v-else> [Transmission] </span>
      <span v-if="carDetail.Country">
        <span class="divider">|</span> {{ carDetail.Country }}</span
      >
      <span v-else> [Country] </span>
    </v-card-text>
    <v-card-text class="pb-0">
      <span class="text-primary pb-0 price" v-if="price">
        {{ priceData }}
      </span>
    </v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12" class="pt-0">
          <p class="labelText">
            {{ $t("carcard.label.delivery") }} > {{ carDetail.DeliveryCountry || $store.state.search.destinationCountry.Name }}<br/>
            {{
              includeFees ? $t("carcard.help.including") : $t("carcard.help.delivery")
            }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import HeartIcon from "vue-material-design-icons/Heart.vue";
import HeartOutlineIcon from "vue-material-design-icons/HeartOutline.vue";
export default {
  props: {
    carDetail: {
      type: Object,
      required: true,
    },
    price: {
      type: Object,
      required: false,
    },
    includeFees: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      imageLink: this.carDetail.Images,
      fav: this.carDetail.IsWishList,
    };
  },
  components: {
    HeartIcon,
    HeartOutlineIcon,
  },
  computed: {
    priceData() {
      try {
        let price = 0,
            currency = "cad";
        if (this.price.Amount && this.price.CurrencyCode) {
          price = this.price.Amount;
          currency = this.price.CurrencyCode;
        } else if (this.carDetail.Price.Amount && this.carDetail.Price.CurrencyCode) {
          price = this.carDetail.Price.Amount;
          currency = this.carDetail.Price.CurrencyCode;
        }
        return this.currencyFormatter(price, currency);
      }catch{
        return null
      }
    },
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    currencyFormatter(value, currency) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    setWishList() {
      if (this.$store.getters.user) {
        this.$store.dispatch("updateWishList", this.carDetail.ListingId);
        this.fav = !this.fav;
      } else {
        this.$bvModal.show("signin");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.carImg {
  max-width: 100%;
  height: 237px;
}
.labelText {
  color: #828282 !important;
  font-size: 11px;
  border-top: 1px solid #cdcdcd;
  padding-top: 8px;
  margin-top: 8px;
  margin-bottom: 0;
  display: block;
}
.comingSoon {
  position: absolute;
  padding: 0 !important;
  margin-top: -185px;
  margin-left: 10px;
}
.cardTitle {
  font-size: 16px;
  font-weight: bold;
  color: black !important;
}
.detailText {
  font-size: 0.8em;
  font-weight: bold;
}
.divider {
  color: #cdcdcd;
  color: #cdcdcd;
  height: 16px;
  margin: 0 8px;
}
.price {
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
}
.heartCircle {
  height: 32px !important;
  width: 32px !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 0.2);
  position: absolute;
  padding: 0 !important;
  right: 0;
  margin-top: -50px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  cursor: pointer;
}
</style>
